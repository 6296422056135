<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-md-4 row-cols-lg-6 g-2 justify-content-end"
      >
        <div class="col">
          <select aria-label="" v-model="searchData.pagesize">
            <option value="10" selected>
              {{ $t("filter.common.sort_by_10") }}
            </option>
            <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
            <option value="100">
              {{ $t("filter.common.sort_by_100") }}
            </option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.status_cd"
          >
            <!-- <option value="" disabled>{{ $t("title.common.status") }}</option> -->
            <option value="-1">{{ $t("filter.common.total") }}</option>
            <option value="0">{{ $t("text.common.ready") }}</option>
            <option value="1">{{ $t("text.common.playing") }}</option>
            <option value="2">{{ $t("text.common.completed") }}</option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.type"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("filter.common.holeinone") }}</option>
            <option value="2">{{ $t("filter.common.albatross") }}</option>
            <option value="3">{{ $t("filter.common.eagle_p5") }}</option>
            <option value="4">{{ $t("filter.common.birdie_p5") }}</option>
            <option value="5">{{ $t("filter.common.par_p5") }}</option>
            <option value="6">{{ $t("filter.common.eagle_p4") }}</option>
            <option value="7">{{ $t("filter.common.birdie_p4") }}</option>
            <option value="8">{{ $t("filter.common.par_p4") }}</option>
            <option value="9">{{ $t("filter.common.longest") }}</option>
            <option value="10">{{ $t("filter.common.nearpin") }}</option>
            <option value="11">
              {{ $t("filter.common.longest_ranking") }}
            </option>
            <option value="12">
              {{ $t("filter.common.nearpin_ranking") }}
            </option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="1">{{ $t("filter.common.title") }}</option>
            <!-- <option value="2">Location</option> -->
            <option value="3">{{ $t("filter.common.course") }}</option>
          </select>
        </div>
        <div class="col-12 col-lg-4 flex-grow-1">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board toggle-xxl">
        <thead>
          <tr>
            <th class="d-xxl-table-cell">
              {{ $t("title.common.title") }}
            </th>
            <!-- <th class="d-none d-xxl-table-cell">
              {{ $t("title.common.type") }}
            </th> -->
            <th class="d-none d-md-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="d-none d-xxl-table-cell">
              {{ $t("title.common.course") }}({{ $t("title.common.hole") }})
            </th>
            <!--             <th class="d-none d-xxl-table-cell w-1">
              {{ $t("title.common.hole") }}
            </th> -->
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.prizes") }}
            </th>
            <th class="d-none d-xxl-table-cell w-1">
              {{ $t("title.common.entry_limit") }}
            </th>
            <th class="d-none d-md-table-cell">
              {{ $t("title.common.status") }}
            </th>
            <th class="d-xxl-table-cell w-1">
              {{ $t("title.common.leaderboard") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <!-- <td class="d-none d-lg-table-cell">{{ item.no }}</td> -->
              <td class="d-xxl-table-cell text-start">
                <a
                  class="d-inline-block pointer"
                  @click="showModal(item.eventno)"
                >
                  <span class="fs-12 text-capitalize text-pink">{{
                    event_type(item.type)
                  }}</span
                  ><br />
                  <span class="more">{{ item.title }}</span>
                </a>
              </td>
              <!-- <td class="d-none d-lg-table-cell">
                {{ event_type(item.type) }}
              </td> -->
              <td class="w-1 d-none d-md-table-cell">
                <!-- 사용자 시간 -->
                <template v-if="item.timezone_startdate.timezone_private">
                  <p class="text-date">
                    <dfn
                      class="time-zone-mark badge rounded-pill bg-orange"
                      title="Connect time"
                      >CT</dfn
                    >
                    {{ item.timezone_startdate.timezone_private | dateformat }}
                    ~
                    {{ item.timezone_enddate.timezone_private | dateformat }}
                  </p>
                </template>
                <!-- 서비스 시간 -->
                <p class="text-date">
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{ item.timezone_startdate.timezone_common | dateformat }}
                  ~
                  {{ item.timezone_enddate.timezone_common | dateformat }}
                </p>
              </td>
              <td class="d-none d-xxl-table-cell">
                {{ item.coursename }} {{ item.subcoursename }}({{
                  item.hole + "H"
                }})
              </td>
              <!--               <td class="d-none d-xxl-table-cell w-1">
                {{ item.hole + "H" }}
              </td> -->
              <td class="d-none d-lg-table-cell text-prize">
                {{ item.type < 11 ? null || item.point : "Rank Prize" }}
              </td>
              <td class="d-none d-xxl-table-cell text-red">
                {{ item.entrylimit | transToValue("∞") }}
              </td>
              <td class="d-none d-md-table-cell">
                {{ event_status(item.status_cd) }}
              </td>
              <td class="d-xxl-table-cell">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-xs w-75"
                  v-if="item.type > 10"
                  @click="
                    $router.push({
                      name: 'MiniGameRanking',
                      params: { eventno: item.eventno },
                    })
                  "
                >
                  {{ $t("button.common.ranking") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-xs w-75"
                  v-else
                  @click="
                    $router.push({
                      name: 'WinAMiniGameList',
                      params: { eventno: item.eventno },
                    })
                  "
                >
                  {{ $t("button.common.view") }}
                </button>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-xxl-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="7">
                <div class="toggle-content">
                  <!-- <div class="row d-lg-none">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col-8">{{ event_type(item.type) }}</div>
                  </div> -->
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8">
                      <!-- 사용자 시간 -->
                      <template v-if="item.timezone_startdate.timezone_private">
                        <p class="text-date">
                          <dfn
                            class="time-zone-mark badge rounded-pill bg-orange"
                            title="Connect time"
                            >CT</dfn
                          >
                          {{
                            item.timezone_startdate.timezone_private
                              | dateformat
                          }}
                          ~
                          {{
                            item.timezone_enddate.timezone_private | dateformat
                          }}
                        </p>
                      </template>
                      <!-- 서비스 시간 -->
                      <p class="text-date">
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{
                          item.timezone_startdate.timezone_common | dateformat
                        }}
                        ~
                        {{ item.timezone_enddate.timezone_common | dateformat }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.course") }}</div>
                    <div class="col-8">
                      {{ item.coursename }} {{ item.subcoursename }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4">{{ $t("title.common.hole") }}</div>
                    <div class="col-8">
                      {{ item.hole + "H" }}
                    </div>
                  </div>
                  <div class="row d-lg-none">
                    <div class="col-4">{{ $t("title.common.prizes") }}</div>
                    <div class="col-8 text-prize">
                      {{ item.type < 11 ? null || item.point : "Rank Prize" }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.entry_limit") }}
                    </div>
                    <div class="col-8 text-red">
                      {{ item.entrylimit | transToValue("∞") }}
                    </div>
                  </div>
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.status") }}</div>
                    <div class="col-8">
                      {{ event_status(item.status_cd) }}
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.leaderboard") }}
                    </div>
                    <div class="col-8">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs"
                        v-if="item.type > 10"
                        @click="
                          $router.push({
                            name: 'MiniGameRanking',
                            params: { eventno: item.eventno },
                          })
                        "
                      >
                        {{ $t("button.common.ranking") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs"
                        v-else
                        @click="
                          $router.push({
                            name: 'WinAMiniGameList',
                            params: { eventno: item.eventno },
                          })
                        "
                      >
                        {{ $t("button.common.view") }}
                      </button>
                    </div>
                  </div> -->
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>

      <TermDefinition getname="event" />

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>

    <b-modal
      id="modal-minigame-info"
      ref="modal-minigame-info"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("menu.event.minigame_informaition") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
        </b-button>
      </template>

      <span class="mb-2 badge bg-pink text-capitalize">
        {{ event_type(eventinfo.type) }}
      </span>
      <p class="modal-subtitle">
        {{ eventinfo.title }}
      </p>

      <div class="row row-cols-1 row-cols-lg-auto gx-2 gy-1 lh-sm">
        <div
          class="col text-date"
          v-if="
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_private
          "
        >
          <!-- 사용자 시간 -->
          <dfn
            class="time-zone-mark badge rounded-pill bg-orange"
            title="Connect time"
            >CT</dfn
          >
          {{
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_private | dateformat
          }}
          ~
          {{
            eventinfo.timezone_enddate &&
            eventinfo.timezone_enddate.timezone_private | dateformat
          }}
        </div>
        <div class="col text-date">
          <!-- 서비스 시간 -->
          <dfn
            class="time-zone-mark badge rounded-pill bg-green"
            title="Standard time"
            >ST</dfn
          >
          {{
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_common | dateformat
          }}
          ~
          {{
            eventinfo.timezone_enddate &&
            eventinfo.timezone_enddate.timezone_common | dateformat
          }}
        </div>
      </div>

      <div class="row g-0 table-type header-col p-keep mt-3">
        <div class="col-sm-4">
          {{ $t("title.common.course") }}
        </div>
        <div class="col-sm-8">
          {{ eventinfo.coursename }} {{ eventinfo.description }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.hole") }}
        </div>
        <div class="col-sm-8">{{ eventinfo.hole }}H</div>

        <div class="col-sm-4">
          {{ $t("title.common.level") }}
        </div>
        <div class="col-sm-8">
          {{ event_level(eventinfo.level_fk) }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.entry_limit") }}
        </div>
        <div class="col-sm-8 text-red">
          {{ eventinfo.entrylimit | transToValue("∞") }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.teebox") }}
        </div>
        <div class="col-sm-8">
          <div class="row row-cols-1 row-cols-lg-auto gy-2">
            <div class="col">
              {{ $t("text.common.male") }} :
              {{ eventinfo.teeboxman | teeboxType }}
            </div>
            <div class="col">
              {{ $t("text.common.female") }} :
              {{ eventinfo.teeboxwoman | teeboxType }}
            </div>
          </div>
        </div>

        <template v-if="eventinfo.type == '9' || eventinfo.type == '10'">
          <div class="col-sm-4">
            {{ $t("title.common.distance") }}
          </div>
          <div class="col-sm-8">
            {{
              eventinfo.distance != "0"
                ? eventinfo.distance + "m"
                : $t("text.common.unregistered")
            }}
          </div>
        </template>

        <div class="col-sm-4">
          {{ $t("title.common.prizes") }}
        </div>
        <div class="col-sm-8 text-prize" v-if="eventinfo.type < 11">
          {{ eventinfo.point }}
        </div>
        <div class="col-sm-8 text-point" v-else>
          <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-2 g-xl-3">
            <template v-for="(item, index) in awardprizeranklist">
              <div :key="'a' + index" class="col">
                <div class="card h-100">
                  <div class="card-body w-100">
                    <h6 class="card-title fs-6">
                      {{ $t("title.common.top_rank") }} {{ item.rank_no }}
                    </h6>
                    <p class="card-text pt-1 lh-sm text-prize">
                      {{ item.prize }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template v-if="eventinfo.standard_point != 0">
          <div class="col-sm-4">
            {{ $t("title.common.point_allocation") }}
          </div>
          <div class="col-sm-8 text-point-sp">
            {{ $t("title.common.standard_point") }}
          </div>
        </template>
      </div>

      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import TermDefinition from "@/components/TermDefinition";
import ax from "@/api/event";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "MiniGame",
  components: {
    location,
    TermDefinition,
  },
  data() {
    return {
      searchData: {
        pageno:
          parseInt(this.$route.query.page) ||
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        type:
          this.$route.query.type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].type) ||
          0,
        status_cd:
          this.$route.query.status_cd ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].status_cd) ||
          "1",
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
      },
      eventinfo: {},
      awardprizeranklist: [],
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      eventlistcnt: 0,
      view_hide: null,
    };
  },

  methods: {
    get_eventlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_eventlist(this.searchData, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          if (data.eventlistcnt <= 0) {
            this.data_yn = false;
            // return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.eventlistcnt;
          this.page_total = Math.ceil(
            data.eventlistcnt / this.searchData.pagesize
          );
          this.items = data.eventlist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    showModal(eventno) {
      // console.log(hole,type);
      ax.get_eventview(eventno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.eventinfo = data.eventinfo;
          this.eventinfo.startdate = this.$moment(
            this.eventinfo.startdate
          ).format("YYYY-MM-DD");
          this.eventinfo.enddate = this.$moment(this.eventinfo.enddate).format(
            "YYYY-MM-DD"
          );
          this.awardprizeranklist = data.awardprizerank;
          this.$refs["modal-minigame-info"].show();
        } else {
          alert(data.message);
        }
      });
    },
    hideModal() {
      this.$refs["modal-minigame-info"].hide();
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      // this.$router.push({
      //   query: {
      //     searchname: this.searchData.searchname,
      //     searchtype: this.searchData.searchtype,
      //     type: this.searchData.type,
      //   },
      // });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_eventlist();
  },
  watch: {
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_eventlist();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_eventlist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_eventlist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_eventlist();
    },
    "searchData.status_cd": function () {
      this.searchData.pageno = 1;
      this.get_eventlist();
    },
    "searchData.pageno": function () {
      this.get_eventlist();
    },
  },
  mixins: [myMixin],
};
</script>